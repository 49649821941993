var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('b-container',{attrs:{"fluid":""}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-overlay',{attrs:{"show":_vm.isLoading,"rounded":"sm"}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols":"3","label-cols-lg":"3","label":"1.Chương trình đào tạo/Bồi dưỡng:","label-for":"trainingSystemSelected"}},[_c('v-select',{attrs:{"options":_vm.trainingSystems,"reduce":function (option) { return option.value; },"clearable":false,"placeholder":"1.Chọn chương trình đào tạo/Bồi dưỡng"},model:{value:(_vm.trainingSystemSelected),callback:function ($$v) {_vm.trainingSystemSelected=$$v},expression:"trainingSystemSelected"}})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"7"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols":"3","label-cols-lg":"3","label":"2.Khóa - Học kỳ:","label-for":"coursesSemestersSelected"}},[_c('v-select',{attrs:{"options":_vm.coursesSemestersOptions,"reduce":function (option) { return option.value; },"clearable":false,"placeholder":"2.Chọn khóa - học kỳ"},model:{value:(_vm.coursesSemestersSelected),callback:function ($$v) {_vm.coursesSemestersSelected=$$v},expression:"coursesSemestersSelected"}})],1)],1)],1)],1)],1),_c('validation-observer',{ref:"studentExaminationPointFormRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.coursesSemestersSelected !== null),expression:"coursesSemestersSelected !== null"},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticStyle:{"margin-right":"5px"},attrs:{"variant":"success"},on:{"click":_vm.onSelectStudentGraduationProject}},[_c('span',{staticClass:"text-nowrap text-right"},[_vm._v("Danh sách")])]),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.studentGraduationProjects.length > 0 && _vm.isAllowRegister),expression:"studentGraduationProjects.length > 0 && isAllowRegister"},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","disabled":invalid},on:{"click":function($event){return _vm.onSave(_vm.studentGraduationProjects)}}},[_c('span',{staticClass:"text-nowrap text-right"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_vm._v(" Lưu lại ")],1)])],1)])],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('vue-good-table',{staticClass:"my-table",attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.studentGraduationProjects,"pagination-options":_vm.paginationOptions,"line-numbers":true,"style-class":"my-table vgt-table striped bordered"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'score')?_c('span',[_c('validation-provider',{attrs:{"rules":"required|between:0,10","name":"Điểm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":props.row.locked === 1,"tabindex":props.row.originalIndex + 1},on:{"change":function($event){return _vm.changeCell(
                              props.row.score,
                              props.column.field,
                              props.row.originalIndex
                            )}},model:{value:(props.row.score),callback:function ($$v) {_vm.$set(props.row, "score", $$v)},expression:"props.row.score"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):(props.column.field === 'note')?_c('span',[_c('b-form-input',{attrs:{"disabled":props.row.locked === 1,"type":"text"},on:{"change":function($event){return _vm.changeCell(
                            props.row.note,
                            props.column.field,
                            props.row.originalIndex
                          )}},model:{value:(props.row.note),callback:function ($$v) {_vm.$set(props.row, "note", $$v)},expression:"props.row.note"}})],1):_c('span',[_vm._v(_vm._s(props.formattedRow[props.column.field]))])]}}],null,true)},[_c('div',{staticStyle:{"text-align":"center","font-weight":"bold"},attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" Không có bản ghi nào ! ")])])],1)],1)]}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }